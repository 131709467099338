import logo from './logo.svg';
import './App.css';
import HomePage from './components/home';
import Navbar from './components/navbar';

// JavaScript code









function App() {
  return (

    <div className = "App" >
      <Navbar / >
    <HomePage / >
    </div>
  );
}

export default App;